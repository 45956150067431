var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 商品列表 ")]},proxy:true},{key:"input",fn:function(){return [_c('a-input',{attrs:{"placeholder":"请输入关键字"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('a-button',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['commodity:list:query']),expression:"['commodity:list:query']"}],staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.search()}}},[_vm._v("搜索")]),_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.code == '2' || _vm.code == '3' || _vm.code == '9'),expression:"code == '2' || code == '3' || code == '9'"}],staticClass:"all_boder_btn",on:{"click":_vm.onAddCommodity}},[_vm._v("新建商品")])]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('a-tabs',{attrs:{"default-active-key":"1"},on:{"change":_vm.callback}},[_c('a-tab-pane',{key:0,attrs:{"tab":"全部商品"}}),_vm._l((_vm.classData),function(item){return _c('a-tab-pane',{key:item.code,attrs:{"tab":item.name}})})],2),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item) { return item.productId; },"columns":_vm.columns,"data-source":_vm.tableData,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.pageNum,
        defaultPageSize: _vm.pageSize,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.pageNum - 1) * 10 + i + 1)+" ")])]}},{key:"sort",fn:function(item,row){return [_c('a-input-number',{attrs:{"id":"inputNumber","min":1},on:{"change":function($event){return _vm.onSortChange(item,row)}},model:{value:(item),callback:function ($$v) {item=$$v},expression:"item"}})]}},{key:"operation",fn:function(text, row){return [(row.type == 3 || row.type == 2 || row.type == 9)?_c('div',{staticClass:"btn_router_link"},[(text.noGround != '已上架')?_c('router-link',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['commodity:publish:updatestatus']),expression:"['commodity:publish:updatestatus']"}],attrs:{"to":""},nativeOn:{"click":function($event){return _vm.changeState(text, 1)}}},[_vm._v("上架")]):_c('router-link',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['commodity:publish:updatestatus']),expression:"['commodity:publish:updatestatus']"}],attrs:{"to":""},nativeOn:{"click":function($event){return _vm.changeState(text, 0)}}},[_vm._v("下架")]),_c('span',[_vm._v("|")]),_c('router-link',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['commodity:info:edit']),expression:"['commodity:info:edit']"}],attrs:{"to":""},nativeOn:{"click":function($event){return _vm.selEdit(text)}}},[_vm._v("编辑")]),(text.noGround != '已上架')?_c('span',[_vm._v("|")]):_vm._e(),(text.noGround != '已上架')?_c('router-link',{directives:[{name:"hasPermi",rawName:"v-hasPermi",value:(['commodity:info:delete']),expression:"['commodity:info:delete']"}],attrs:{"to":""},nativeOn:{"click":function($event){return _vm.deleteitem(text)}}},[_vm._v("删除")]):_vm._e()],1):_c('div',[_vm._v("-")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }